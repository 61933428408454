.container {
  position: relative;
}

.content {
  position: absolute;
  display: none;
  background: var( --accent-color );
  border: 1px solid var( --accent-color );
  border-radius: 0.3rem;
  padding: 0.5rem;
  /* margin-top: -0.5rem; */
  width: 100dvw;
  max-width: 300px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 0.9rem;
  z-index: 1;
  color: white;
  opacity: 0.95;
}

.content[data-size="small"] {
  max-width: 100px;
}

.content[data-size="medium"] {
  max-width: 200px;
}

.content[data-size="large"] {
  max-width: 300px;
}

.container:hover .content {
  pointer-events: none;
  display: block;
}

.content[data-position="top"] {
  margin-top: -0.5rem;
  top: 0;
  transform: translate(-50%, -100%);
}

.content[data-position="bottom"] {
  margin-top: 0.5rem;
  top: 100%;
  transform: translate(-50%, 0);
}

.content[data-position="left"] {
  margin-left: -0.5rem;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
}

.content[data-position="right"] {
  margin-left: 0.5rem;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
}
