.content.cart > .layout,

.content.checkout > .layout {

    background-color: #FFF;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 2em;

    border-radius: 0.3em;
    
}

.content.cart > .layout > section > div:not( :last-child ),

.content.checkout > .layout > section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.content.cart > .layout > section > .checkout-actions {

    text-align: right;

}

.content.cart > .layout > section > .checkout-actions > a {

    display: inline-block;

    text-decoration: none;

    padding: 1em;

    background-color: var( --black-color );

    color: var( --white-color );

    font-size: 1.1em;

    text-transform: uppercase;

    border: none;

    font-weight: 600;

    border-radius: 0.3em;

    outline: none;

}

.content.cart > .layout > section > .checkout-actions > a:hover,

.content.cart > .layout > section > .checkout-actions > a:focus {

    cursor: pointer;

    background-color: var( --accent-color );

}

.content.cart > .layout > section > .items {

    border-top: 1px solid var( --details-color );

    border-bottom: 1px solid var( --details-color );

    padding-top: 1em;

    padding-bottom: 1em;

}

.content.cart > .layout > section > .items > .item:not( :last-child ) {

    border-bottom: 1px solid var( --details-color );

    padding-bottom: 1em;

    margin-bottom: 1em;

}

.content.cart > .layout > section > .items > .item > div:not( :last-child ) {

    margin-bottom: 1em;

}

.content.cart > .layout > section > .items > .item > .remove {

    text-align: right;

}

.content.cart > .layout > section > .items > .item > .data {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

}

.content.cart > .layout > section > .items > .item > .data > div:not( :last-child ) {

    margin-right: 2em;

}

.content.cart > .layout > section > .items > .item > .data > div:not( :first-child ):not( :last-child ) {

    flex: 1;

}

.content.cart > .layout > section > .items > .item > .data > .quantity {

    text-align: center;

}

.content.cart > .layout > section > .items > .item > .data > .thumbnail {

    position: relative;

    width: 100%;

    max-width: 100px;

}

.content.cart > .layout > section > .items > .item > .data > .thumbnail > a > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 0;

    left: 0;

    object-fit: contain;

}

.content.cart > .layout > section > .items > .item > .data > .thumbnail::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.content.cart > .layout > section > .items > .item > .data > .name > div:not( :last-child ),

.content.cart > .layout > section > .items > .item > .data > .price > div:not( :last-child ){

    margin-bottom: 0.5em;

}

.content.cart > .layout > section > .items > .item > .data > .name > .product > a {

    text-decoration: none;

    outline: none;

    color: var( --black-color );

    font-weight: 600;

    font-size: 18px;

}

.content.cart > .layout > section > .items > .item > .data > .name > .product > a:hover {

    color: var( --accent-color );

}

.content.cart > .layout > section > .items > .item > .data > .name > .parameters > span,

.content.cart > .layout > section > .items > .item > .data > .price > .heading {

    font-weight: 500;

}

.content.cart > .layout > section > .items > .item > .data > .price > .pricing {

    color: var( --accent-color );

    font-size: 18px;

    font-weight: 600;

}

.content.cart > .layout > section > .items > .item > .remove > button {

    line-height: 0;

    border: none;

    background: none;

    outline: none;

    font-size: 16px;

}

.content.cart > .layout > section > .items > .item > .remove > button:hover,

.content.cart > .layout > section > .items > .item > .remove > button:focus {

    color: var( --accent-color );

    cursor: pointer;

}

.content.cart > .layout > section > .items > .item > .data > .quantity {

    display: inline-flex;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

}

.content.cart > .layout > section > .items > .item > .data > .quantity > div:not( :last-child ) {

    margin-right: 16px;

}

.content.cart > .layout > section > .items > .item > .data > .quantity > .quantity > input {

    padding: 10px 20px;

    border: none;

    outline: none;

    border-radius: 0.3em;

    box-shadow: 0 0 1em 0.2em rgb(0 0 0 / 10%);

    width: 100%;

    max-width: 78px;

    text-align: center;

    -moz-appearance: textfield;

}

.content.cart > .layout > section > .items > .item > .data > .quantity > .quantity > input::-webkit-outer-spin-button,
.content.cart > .layout > section > .items > .item > .data > .quantity > .quantity > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.content.cart > .layout > section > .items > .item > .data > .quantity > .up > button, 

.content.cart > .layout > section > .items > .item > .data > .quantity > .down > button {

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: center;

    padding: 10px;

    border: none;

    outline: none;

    border-radius: 0.3em;

}

.content.cart > .layout > section > .items > .item > .data > .quantity > .up > button {

    color: var( --white-color );

    background-color: var( --accent-color );

}

.content.cart > .layout > section > .items > .item > .data > .quantity > .down > button {

    background-color: var( --black-color );

    color: var( --white-color );

}

.content.cart > .layout > section > .items > .item > .data > .quantity > .up > button:hover,

.content.cart > .layout > section > .items > .item > .data > .quantity > .down > button:hover {

    cursor: pointer;

}

.content.cart .cart_totals {

    display: flex;

    justify-content: flex-end;

    flex-direction: row;

    flex-wrap: nowrap;

    font-size: 18px;

}

.content.cart .cart_totals > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

.content.cart .cart_totals > ul > li.highlighted {

    font-weight: 500;

}

.content.compare > .layout > section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.content.compare > .layout > section > div {

    border-radius: 0.3em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 1.5em;

}

.content.compare .table > div:not( :last-child ) {

    margin-bottom: 1em;

}

.content.compare .table > .title {

    font-weight: 600;

}

.content.compare .table > .items {

    display: grid;

    grid-template-columns: repeat( 4, 1fr );

    grid-gap: 1em;

}

.content.compare .table > .items > .content {

    position: relative;

    min-height: 50px;

}

.compare-empty {

    position: absolute;

    width: 100%;

    height: 100%;

    border: 1px dashed var( --details-color );

    border-radius: 0.3em;

}

.content.compare .table > .items > .content > .item {

    border: 1px dashed var( --details-color );

    border-radius: 0.3em;

}

.content.compare .table > .items > .content > .item > div {

    padding: 0.5em;

    text-align: center;

}

.content.compare .table > .items > .content > .item > div:not( :last-child ) {

    border-bottom: 1px dashed var( --details-color );

}

.content.compare .table > .items > .content > .item > .property > .name {

    font-size: 12px;

    font-weight: 500;

    color: var( --secondary-text-color );

}

.content.compare .table > .items > .content > .item > .property > .value {

    text-transform: uppercase;

}

.content.compare .table > .items > .content > .item > .property.price > .value {

    color: var( --accent-color );

    font-weight: 500;

}

.content.compare .table > .items > .content > .item > .compare-actions {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    justify-content: center;

}

.content.compare .table > .items > .content > .item > .compare-actions > *:not( :last-child ) {

    margin-right: 1rem;

}

.content.compare .table > .items > .content > .item > .compare-actions > button {

    border: none;

    outline: none;

    background-color: var( --accent-color );

    padding: 10px;

    font-size: 16px;

    border-radius: 0.3rem;

    color: var( --white-color );

    line-height: 0;

}

.content.compare .table > .items > .content > .item > .compare-actions > button:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}

.content.compare .table > .items > .content > .item > .compare-actions > button:disabled {

    pointer-events: none;

    opacity: 0.5;

}   

/* .compare section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.compare .table > div:not( :last-child ) {

    margin-bottom: 1em;

}

.compare .table > .title {

    font-weight: 500;

    text-transform: uppercase;

    font-size: 20px;

}

.compare .table > .items {

    display: grid;

    grid-template-columns: repeat( 5, 1fr );

} */

.content.cart .receipt > div:not( :last-child ),

.content.checkout .receipt > div:not( :last-child ){

    margin-bottom: 2em;

}

.content.cart .receipt > .message,

.content.checkout .receipt > .message {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

    border: 1px solid #b71c1c;

    border-radius: 0.3em;

    padding: 1em;

    line-height: 2;

}

.content.cart .receipt > .message.failed,

.content.checkout .receipt > .message.failed {

    border: 1px solid #b71c1c;

}

.content.cart .receipt > .message.success,

.content.checkout .receipt > .message.success {

    border: 1px solid green;

}

.content.cart .receipt > .message > .icon.failed,

.content.checkout .receipt > .message > .icon.failed {

    color: #b71c1c;

}

.content.cart .receipt > .message > .icon.success,

.content.checkout .receipt > .message > .icon.success {

    color: green;

}

.content.cart .receipt > .message > .icon,

.content.checkout .receipt > .message > .icon {

    line-height: 0;

    font-size: 32px;

    margin-right: 0.5em;

}

.content.cart .receipt > .action > a,

.content.checkout .receipt > .action > a {

    background-color: var(--accent-color );

    text-align: center;

    line-height: 2;

    text-decoration: none;

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    text-transform: uppercase;

    font-weight: 600;

    font-size: 1.1em;

}


.content.cart .receipt > .action > a:hover,

.content.checkout .receipt > .action > a:hover {

    cursor: pointer;

    background-color: var(--accent-focus-color );

    color: #000;

}

/** Checkout */

.content.checkout > .layout > section > .sector > *:not( :last-child ) {

    margin-bottom: 1em;

}

.content.checkout > .layout > section > .sector > .title {

    font-weight: 500;

}

.checkout .radios > .radio > label > .icon {

    position: relative;

    display: flex;

    justify-content: center;

    align-items: center;

    width: 20px;

    height: 20px;

    border-radius: 50%;

    border: 2px solid var(--details-color );

    margin-right: 8px;

}

.checkout .radios > .radio > label > .icon > .checked {

    visibility: hidden;

    line-height: 0;

    font-size: 14px;

    color: var( --accent-color );

}

.checkout .radios > .radio > label > input[ type="radio" ] {

    display: none;

}

.checkout .radios > .radio > label > input[type="radio"]:checked ~ span.icon { border-color: var( --accent-color ); }

.checkout .radios > .radio > label > input[type="radio"]:checked ~ span.icon > span.checked { visibility: visible; }

.checkout .radios > .radio > label {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}   

.checkout .sector > .radios {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout .sector > .radios > div:not( :last-child ) {

    margin-right: 32px;

}

.checkout .sector .fields > .form-group > * {

    display: block;

}

.checkout .sector .fields > .form-group > *:not( :last-child ) {

    margin-bottom: 8px;

}

.checkout .sector .fields {

    display: grid;

    grid-template-columns: repeat( 3, 1fr );

    grid-gap: 1em;

    align-items: center;

}

.checkout .sector .fields > .form-group > input,

.checkout .sector .fields > .form-group > select {

    width: 100%;

    border: 2px solid var( --details-color );

    border-radius: 0.3em;

    outline: none;

    padding: 8px;

}

.checkout .select-option-placeholder {

    border: 1px dashed var( --details-color );

    padding: 16px;

    border-radius: 0.3em;

    font-weight: 500;

}

.checkout .sector .pickup,

.checkout .sector .cash {

    background-color: #e8f5e9;
    
    border: 1px solid #c8e6c9;
    
    color: #1b5e20;
    
    padding: 12px;
    
    border-radius: 0.3em;
    
    font-size: 0.9em;
    
    font-weight: 500;

}

.checkout .sector .table {

    border: 1px dashed var( --details-color );

    border-radius: 0.3em;

}

.checkout .sector .table > div:not( :last-child ) {

    border-bottom: 1px dashed var( --details-color );

}

.checkout .sector .table > .row {

    display: grid;

    grid-template-columns: repeat( 2, 1fr );

    align-items: center;

}

.checkout .sector .table > .row > div:not( :last-child ) {

    border-right: 1px dashed var( --details-color );

}

.checkout .sector .table > .row > div {

    padding: 8px;

}

.checkout .sector .table > .row > .text {

    font-weight: 500;

}

.checkout .sector .table > .row > .text.items {

    font-weight: 400;

}

.checkout .sector .table > .row > .text > span.manufacturer {

    text-transform: uppercase;

}

.checkout .sector .table > .row > .text > span.quantity {

    font-weight: 500;

}

.checkout .policy-validation > label > span.name {

    line-height: 2;

}   

.checkout .policy-validation > label > span.name > a,

.checkout .policy-validation > .message > a {

    color: var( --accent-color );

    outline: none;

}

.checkout .policy-validation > .message {

    font-size: 0.9em;

}

.checkout .policy-validation > div:not( :last-child ) {

    margin-bottom: 8px;

    line-height: 2;

}

.checkout .submit_checkout {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    font-weight: 600;

    outline: none;

    font-size: 1.1em;

}

.checkout .submit_checkout:hover {

    cursor: pointer;

    background-color: var(--accent-focus-color );

    color: #000;

}

.checkout .submit_checkout:disabled {

    pointer-events: none;

    opacity: 0.5;

}   

.content.cart .update-cart {

    text-align: right;

}

.content.cart .update-cart > button {

    display: inline-flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

    outline: none;

    border: none;

    background-color: var( --accent-color );

    color: var( --white-color );

    padding: 8px;

    border-radius: 0.3em;

    font-weight: 600;

}

.content.cart .update-cart > button > span {

    line-height: 0;

    font-size: 24px;

    margin-right: 5px;

}

.content.cart .update-cart > button:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}

.content.cart .update-cart > button:disabled {

    pointer-events: none;

    opacity: 0.5;

}

.checkout .checkout-errors {

    background-color: #ffebee;

    border: 1px solid #ffcdd2;

    border-radius: 0.3em;

    padding: 16px;

}

.checkout .checkout-errors > div:not( :last-child ) {

    margin-bottom: 8px;

}

.checkout .checkout-errors > .error {

    color: #b71c1c;

    font-weight: 500;

    font-size: 14px;

}

.content.compare .table > .items > .content > .item > .thumbnail {

    position: relative;

}

.content.compare .table > .items > .content > .item > .thumbnail::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.content.compare .table > .items > .content > .item > .thumbnail > img {

    position: absolute;

    top: 50%;

    left: 50%;

    width: 80%;

    height: 80%;

    transform: translate( -50%, -50% );

    border-radius: 0.3em;

    object-fit: contain;

}

.content.compare .table > .items > .content > .item > .removeCompare {

    position: absolute;

    border: 0;

    outline: none;

    color: var( --accent-color );

    background-color: transparent;

    line-height: 0;

    font-size: 24px;

    right: -12px;

    top: -12px;

}

.content.compare .table > .items > .content > .item > .removeCompare:hover {

    cursor: pointer;

    color: #000;

}

.checkout-step-buttons {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout-step-buttons > .return_step_button {

    background-color: #0090ff;

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    font-weight: 600;

    font-size: 1.1em;

}

.checkout-step-buttons > *:not( :last-child ) {

    margin-right: 1rem;

}

.checkout-step-buttons > .return_step_button:hover {

    cursor: pointer;

    background-color: var( --accent-color );

}

.orders_montage {

    display: inline-flex;

    background-color: #FFF;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 2em;

    border-radius: 0.3em;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

}

.orders_montage > *:not( :last-child ) {

    margin-right: 1rem;

}

.orders_montage > .icon {

    line-height: 0;

    color: var( --accent-focus-color );

}

.orders_montage > .description {

    flex: 1;

}

.orders_montage > a {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 0.5rem 1rem;

    font-size: 0.9rem;

    border-radius: 0.3em;

    font-weight: 600;

    outline: none;

    text-decoration: none;

}

.orders_montage > a:hover {

    cursor: pointer;

    background-color: var(--accent-focus-color );

    color: #000;

}

.checkout_sp {

    background-color: #FFF;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 2em;

    border-radius: 0.3em;

}

.checkout_sp > *:not( :last-child ) {

    margin-bottom: 1rem;

}

.checkout_sp > .section {

    position: relative;

    text-align: center;

}

.checkout_sp > .section > span {

    position: relative;

    background-color: white;

    padding-right: 1rem;

    padding-left: 1rem;

    font-weight: 600;

    text-transform: uppercase;

    letter-spacing: 1px;

    font-size: 0.9rem;

}

.checkout_sp > .section::before {

    content: "";

    position: absolute;

    height: 1px;

    width: 100%;

    background: var( --details-color );

    display: block;

    left: 0;

    top: 50%;

    transform: translateY(-50%);

}

.checkout_sp > .sector > *:not( :last-child ) {

    margin-bottom: 1rem;

}

.checkout_sp .form-group {

    display: grid;

    grid-template-columns: repeat( 3, 1fr );

    grid-gap: 1rem;

}

.checkout_sp .field {

    display: flex;

    flex-direction: column;

    flex-wrap: nowrap;

    align-items: flex-start;

    justify-content: flex-start;

}

.checkout_sp .field > label {

    font-weight: 500;

    font-size: 0.9rem;

    margin-bottom: 0.5rem;

}

.checkout_sp .field > input,

.checkout_sp .field > textarea {

    width: 100%;

    border: 2px solid var( --details-color );

    border-radius: 0.3em;

    outline: none;

    padding: 8px;

}

.checkout_sp .field > textarea {

    width: 100%;

    resize: vertical;

    height: 72px;

    min-height: 36px;

    max-height: 300px;

}

.checkout_sp .field > select {

    width: 100%;

    border: 2px solid var( --details-color );

    border-radius: 0.3em;

    outline: none;

    padding: 8px;

}

.checkout_sp .radios {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout_sp .radios > .radio > label {

    padding: 1rem;

    border: 1px solid #d2d2d2;

    border-radius: 0.3rem;

    background-color: #f5f5f5;

}

.checkout_sp .radios > .radio > label.selected {

    pointer-events: none;

    opacity: 0.5;

}   

.checkout_sp .radios > .radio > label:hover {

    cursor: pointer;;

    border-color: #000;

    background-color: transparent;

}

.checkout_sp .radios > div:not( :last-child ) {

    margin-right: 1rem;

}

.checkout_sp .radios > .radio > label > .icon {

    position: relative;

    display: flex;

    justify-content: center;

    align-items: center;

    width: 20px;

    height: 20px;

    border-radius: 50%;

    border: 2px solid var(--details-color );

    margin-right: 8px;

}

.checkout_sp .radios > .radio > label > .icon > .checked {

    visibility: hidden;

    line-height: 0;

    font-size: 14px;

    color: var( --accent-color );

}

.checkout_sp .radios > .radio > label > input[ type="radio" ] {

    display: none;

}

.checkout_sp .radios > .radio > label > input[type="radio"]:checked ~ span.icon { border-color: var( --accent-color ); }

.checkout_sp .radios > .radio > label > input[type="radio"]:checked ~ span.icon > span.checked { visibility: visible; }

.checkout_sp .radios > .radio > label {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout_sp .radios > .radio > label > span {

    font-size: 0.9rem;

    font-weight: 500;

}

.checkout_sp .radios > .radio > label > span:not( :last-child ) {

    margin-right: 0.5rem;

}

/* .checkout_sp .radios > .radio > label > input[type="radio"]:checked ~ span {

    color: var( --accent-color );

} */

.checkout_sp .radios > .radio > label > input[type="radio"]:checked ~ span:not( :last-child ) {

    margin-right: 0.5rem;

}

.checkout_sp .radios > .radio > label > input[type="radio"]:checked ~ span.icon > span.checked {

    visibility: visible;

}

.checkout_sp .radios > .radio > label > input[type="radio"]:checked ~ span.icon {

    border-color: var( --accent-color );

}

.checkout_sp .notification {

    background-color: #e8f5e9;

    border: 1px solid #c8e6c9;

    color: #1b5e20;

    padding: 12px;

    border-radius: 0.3em;

    font-size: 0.9em;

    font-weight: 500;

}

.checkout_sp .agreement > *:not( :last-child ) {

    margin-bottom: 1rem;

}

.checkout_sp .agreement > p {

    font-size: 0.9rem;

    font-weight: 500;

    line-height: 1.5;

}

.checkout_sp .agreement > p > a,

.checkout_sp label a {

    color: var( --accent-color );

    outline: none;

}

.checkout_sp .checkbox {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout_sp .checkbox > label {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout_sp .checkbox > label > input[type="checkbox"] {

    display: none;

}

.checkout_sp .checkbox > label > span {

    position: relative;

    display: inline-flex;

    justify-content: center;

    align-items: center;

    width: 20px;

    height: 20px;

    border-radius: 0.3em;

    border: 2px solid var(--details-color );

    margin-right: 8px;

}

.checkout_sp .checkbox > label > span > .checked {

    visibility: hidden;

    line-height: 0;

    font-size: 14px;

    color: var( --accent-color );

}

.checkout_sp .checkbox > label > input[type="checkbox"]:checked ~ span { border-color: var( --accent-color ); }

.checkout_sp .checkbox > label > input[type="checkbox"]:checked ~ span > span.checked { visibility: visible; }

.checkout_sp .submit {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.checkout_sp .submit > *:not( :last-child ) {

    margin-right: 1rem;

}

.checkout_sp .submit > button {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1rem;

    font-size: 1rem;

    border-radius: 0.3em;

    font-weight: 600;

    outline: none;

    text-decoration: none;

}

.checkout_sp .submit > button:hover {

    cursor: pointer;

    background-color: var(--accent-focus-color );

    color: #000;

}

.checkout_sp .submit > button:disabled {

    pointer-events: none;

    opacity: 0.5;

}

.checkout_sp .submit > span {

    position: relative;

    background: var( --accent-focus-color );

    padding: 0.5rem;

    border-radius: 0.3rem;

    font-size: 0.9rem;

}

/** add arrow left for span element */

.checkout_sp .submit > span::before {

    content: "";

    position: absolute;

    width: 0;

    height: 0;

    border-top: 5px solid transparent;

    border-bottom: 5px solid transparent;

    border-right: 5px solid var( --accent-focus-color );

    left: -5px;

    top: 50%;

    transform: translateY(-50%);

}

.checkout_sp table {

    border-collapse: collapse;

    border: 1px dashed var( --details-color );

    border-radius: 0.3em;

    width: 100%;

    border-radius: 0.3rem;

}

.checkout_sp table tr {

    border-bottom: 1px dashed var( --details-color );

}

.checkout_sp table tr > td:not( :last-child ) {

    border-right: 1px dashed var( --details-color );

}

.checkout_sp table tr > td {

    padding: 0.5rem;

}

.checkout_sp table tr > td.text.accented {

    font-weight: 500;

}

.btn_cart_checkout {

    background-color: black;

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1rem;

    font-size: 1rem;

    border-radius: 0.3em;

    font-weight: 600;

    outline: none;

    text-decoration: none;

    text-transform: uppercase;

}

.btn_cart_checkout:hover {

    cursor: pointer;
    
    background-color: var(--accent-color );

}

span.product_id {

    font-size: 0.9em;

    color: var( --secondary-text-color );

    font-weight: 500;

}

.seasonIcon, .cartParamIcon {

    line-height: 0;

}

.cartParameters {

    display: flex;

    gap: 0.25rem;
    
    align-items: center;

}

.parameters > *:not( :last-child ) {

    margin-bottom: 0.25rem;

}

.seasonWinter {

    color: #2196f3;

}

.seasonSummer {

    color: #FF9800;

}

.checkoutParameters {

    display: block;

}

.checkoutParametersList {

    display: flex;

    flex-direction: row;

    flex-wrap: wrap;

    align-items: center;

    gap: 0.5rem;

}

.cartParam {

    background-color: var(--secondary-text-color);
    color: white;
    border-radius: 0.3rem;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.25rem;

}